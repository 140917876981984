import React from 'react'
import '../../../App.css';
import { useEffect, useState } from 'react';
import Footer from '../../landing-page/Footer/Footer';
import Loader from '../../loader/loader';
import { Link, useNavigate } from 'react-router-dom';
import { validateForgotPassword } from '../../../redux/actions/user_action';
import ErrorMessage from '../../Dashboard/error-message/error-message';

const Logo = require("../../../images/assets/Logo.png");


const ForgotPassword = () => {
    const [passwrd, setPassword] = useState("")
    const [chpasswrd, setchPassword] = useState("")
    const [loader, setLoader] = useState(false)
    const [showError, setShowError] = useState(false)
    const [checkPass, setCheckPass] = useState(false)
    const [message, setMessage] = useState();
    const navigate = useNavigate();

    const resetPassword = () => {

        var url = new URL(window.location.href);
        var searchParams = url.searchParams;
        var tokenAc = searchParams.get('token');
        if (tokenAc) {
            if (passwrd === '' || chpasswrd === '') {
                setShowError(true)
            } else if (passwrd != chpasswrd) {
                setCheckPass(true)
            } else {
                const params = {
                    forgotPasswordToken: tokenAc,
                    password: passwrd,
                    confirmPassword: chpasswrd
                }
                validateForgotPassword(params).then(resp => {
                    if (resp.statusCode === "SUCCESS") {
                        //setMessage({"success" : resp.statusMessage})
                        navigate('/password-changed')

                    }
                }).catch(error => {
                    setLoader(false);
                    if (error?.response.data.errorCode === "B2B_FORGOT_PASSWORD_VALIDATION_400") {
                        setMessage({ "error": error?.response.data.errorMessage })
                    }
                    if (error && error?.response && error?.response.data) {
                        setMessage({ "error": error?.response.data.errorMessage })
                    }else{
                        setMessage({ "error": 'Something went wrong!' });
                    }
                })
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            resetPassword()
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <section>
                                    <ErrorMessage message={message} />
                                </section>
                                <div>
                                    <h1 className='color'>
                                        Change your account password
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mb-md-0'>
                                        Set New Password
                                    </h1>
                                    <p className='fw-semibold mt-md-3'>
                                        Set your new password for Boost.Money so you can login to your account
                                    </p>
                                    <div className='form-input-box login-box'>
                                        <form>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>New Password </div>
                                                </div>
                                                <div>
                                                    <input type="password" id='passwrd' className='input-box form-control' value={passwrd} onChange={(e) => { setPassword(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && passwrd === '') && <span className="demo-tetx">Please enter your password</span>}
                                                </div>
                                            </div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Confirm New Password </div>
                                                </div>
                                                <div>
                                                    <input type="password" id='chpasswrd' className='input-box form-control' value={chpasswrd} onChange={(e) => { setchPassword(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && chpasswrd === '') && <span className="demo-tetx">Please enter your password</span>}
                                                </div>
                                            </div>
                                            {(checkPass) && <span className="demo-tetx">Please verify, New password doesn't match with the confirm password</span>}

                                            <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='row g-2 align-items-center'>

                                                    <div className='col-12 order-1 order-md-2 text-end'>
                                                        <div className='text-end my-2 my-sm-0'>
                                                            <button type='button' className='next-button'
                                                                onClick={() => {
                                                                    resetPassword();
                                                                }}>Reset Password</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default ForgotPassword