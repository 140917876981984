import httpClientB2BProd, { setB2BAuthHeaderProd } from "../../config/prod-b2b";


const createCustomerProd = (data) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            url: 'api/v1/create/test/user',
            data: data,
        }).then(resp => {
            resolve(resp.data);
        })
            .catch(error => {
                reject(error);
            });
    });

const scoreByUploadStatement = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            url: 'api/v1/b2b/score/uploadStatement',
            data: data, headers: {
                "Content-Type": "multipart/form-data",
                "x-reference-id": uuid
            }
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });


const multipleUploadStatementProd = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            url: 'api/v1/b2b/multiple/uploadStatements',
            data: data, headers: {
                "Content-Type": "multipart/form-data",
                "x-reference-id": uuid
            }
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });


const creditReportServiceProd = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            url: 'api/v1/b2b/credit/report/upload',
            data: data, headers: {
                "Content-Type": "multipart/form-data",
                "x-reference-id": uuid
            }
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

const reportDownload = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            responseType: 'blob',
            url: 'api/v1/b2b/boost/report/get',
            data: data,
            headers: { "x-reference-id": uuid }
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });

const downloadExcelReport = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            responseType: 'blob',
            url: 'api/v1/b2b/boost/report/get/excel',
            data: data,
            headers: { "x-reference-id": uuid }
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });

const getBankListProd = (data) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            url: '/api/v1/b2b/get/banks',
            data: data
        }).then(resp => {
            resolve(resp);
        }).catch(error => {
            reject(error.response.data);
        });
    });

const downloadExcelAnalysisReport = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            responseType: 'blob',
            url: 'api/v1/b2b/excel/analysis/download',
            data: data,
            headers: { "x-reference-id": uuid }
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });
const getClientConfiguration = () =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'GET',
            url: '/api/v1/b2b/sdk/get/client/configuration',
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });

const getTokenApiBoostProdAI = (data) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST', data: data,
            url: '/api/v1/b2b/user/token/get',
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });

const gstUploadApiProd = (data) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST', data: data,
            url: '/api/v1/b2b/gst/upload',
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });
const gstExcelDownloadProdApi = (data) =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            responseType: 'blob',
            method: 'POST', data: data,
            url: '/api/v1/b2b/gst/download/excel',
        }).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });

    const getProdInvoiceData = (data) =>
        new Promise((resolve, reject) => {
            httpClientB2BProd({
            method: 'POST',
            url: 'api/v1/b2b/billing/invoice/get',
            data: data
          }).then(resp => {
            resolve(resp);
          }).catch(error => {
            reject(error);
          });
    });

export {
    createCustomerProd,
    scoreByUploadStatement,
    creditReportServiceProd,
    reportDownload,
    downloadExcelReport,
    getBankListProd,
    multipleUploadStatementProd,
    downloadExcelAnalysisReport,
    getClientConfiguration,
    getTokenApiBoostProdAI,
    gstUploadApiProd,
    gstExcelDownloadProdApi,
    getProdInvoiceData
}