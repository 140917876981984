import React, { useState, useRef, forwardRef, useEffect } from "react";
import ChangePassword from "../change_password/change_password";
import "./profile_dropdown.css";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from "../../../../redux/actions/auth_action";
const IC_LOGOUT = require("../../../../images/assets/ic-logout.png");
const IC_SETTING = require("../../../../images/assets/ic-setting.png");
const IC_BILLING = require("../../../../images/assets/bill.png");
const IC_ARROW = require("../../../../images/assets/ic-arrow.png");
const Avatar_logo = require("../../../../images/avatar-profile.png");

const ProfileDropdown = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isConfingShowProd, setConfigShowProd] = useState(true);

  let fullName = localStorage.getItem("fullName")
  let businessName = localStorage.getItem("businessName")

  useEffect(() => {
    setConfigShowProd(JSON.parse(localStorage.getItem('allConfiguration'))?.productionEnable);
  }, []);

  const profilePage = () => {
    navigate('/profile')
    props.setShowProfile(false)
  }
  const billigPage = () => {
    navigate('/billing')
    props.setShowProfile(false)
  }
  const chnagePasswordPage = () => {
    navigate('/change-password')
    props.setShowProfile(false)
  }

  const logout = async () => {
    var result = dispatch(logOut());
    if (result) {
      navigate('/login')
    }
  }

  return (
    <>
      <div className="profile-dropdown-view">
        <div className="pro-row-dropdown">
          <div className="pro-dropdown-circular">
            {/* <img
                  src={Avatar_logo}
                  className="pro-dropdown-avatar"
                  alt="avatar"
                ></img> */}
            <div className="pro-dropdown-avatar">{fullName ? fullName.charAt(0).toUpperCase() : ''}</div>
          </div>
          <div className="pro-dropdown-data">
            <p className="pro-dropdwon-username">{fullName}</p>
            <p className="pro-dropdwon-cmp-name">{businessName}</p>
          </div>
          <div className="pro-dropdown-divider"></div>

          <div className="pro-dropdown-3-items" onClick={() => {
            profilePage()
          }}>
            <div className="pro-dropdown-items">
              <img
                src={IC_SETTING}
                className="pro-dropdown-item-icon"
                alt="setting"
              ></img>
              <p className="pro-dropdown-item-name">Account Settings</p>
            </div>
            {/* <img src={IC_ARROW}  className="pro-dropdown-item-icon"  alt="arrow" ></img> */}
          </div>
          <div className="pro-dropdown-space"></div>

          {isConfingShowProd && <div className="pro-dropdown-3-items" onClick={() => { billigPage() }}>
            <div className="pro-dropdown-items">
              <img
                src={IC_BILLING}
                className="pro-dropdown-item-icon pad-1"
                alt="setting"
              ></img>
              <p className="pro-dropdown-item-name">&nbsp;Billing and Invoice</p>
            </div>
            {/* <img src={IC_ARROW}  className="pro-dropdown-item-icon"  alt="arrow" ></img> */}
          </div>}

          {/* <div className="pro-dropdown-space"></div>

              <div className="pro-dropdown-3-items" onClick={() => { chnagePasswordPage() }}>
                <div className="pro-dropdown-items">
                  <img
                    src={IC_SETTING}
                    className="pro-dropdown-item-icon"
                    alt="setting"
                  ></img>
                  <p className="pro-dropdown-item-name">Change Password</p>
                </div>
                <img src={IC_ARROW}  className="pro-dropdown-item-icon"  alt="arrow" ></img>
              </div> */}

          <div className="pro-dropdown-space"></div>

          <div className="pro-dropdown-items" onClick={() => { logout() }}>
            <img
              src={IC_LOGOUT}
              className="pro-dropdown-item-icon"
              alt="logout"
            ></img>
            <p className="pro-dropdown-item-name">Logout</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDropdown;