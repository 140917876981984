import { useEffect, useState } from 'react';
import MenuBar from '../Menubar/menubar';
import Sidebar from '../SideBar/sidebar';
import Footer from '../../landing-page/Footer/Footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import SvgCopyIcon from '../../Component/svg-copy-icon';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../integration/integration.css'
import './developer.css'
import ErrorMessage from '../error-message/error-message';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getConfiguration, createCallBackUrl, getCallBackUrl } from '../../../redux/actions/dashboard_action';
import Loader from '../../loader/loader';


const copyIcon = require("../../../images/assets/copy-1.png");
const eyeIcon = require("../../../images/assets/ic_edit.png");

function Developer() {
    const [clientId, setClientId] = useState('')
    const [clientSecretId, setClientSecretId] = useState('')
    // const [callBackUrl, setcallBackUrL] = useState('')
    // const [showError, setShowError] = useState(false);
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [loader, setLoader] = useState();


    // const handleChange = event => {
    //     setcallBackUrL(event.target.value);
    // };

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const copied = () => {
        toast("Copied!", {
            toastId: 100, progressStyle: {
                background: '#4243F1', // Change this to the desired color
            }, icon: SvgCopyIcon,
            position: toast.POSITION.BOTTOM_RIGHT, className: 'toast-message'
        });
    }

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        }

        //Get Configuration
        setLoader(true)
        getConfiguration().then(resp => {
            if (resp) {
                setLoader(false)
                setClientId(resp.clientId)
                setClientSecretId(resp.clientSecret)
            }
        }).catch(error => {
            setLoader(false)
        })

        //Get CallBackUrl
        // getCallBackUrl().then(resp => {
        //     if (resp) {
        //         setLoader(false)
        //         setcallBackUrL(resp.callBackUrl)
        //     }
        // }).catch(error => {
        //     setLoader(false)
        //   //  console.log(error)
        // })

    }, [])

    // const configSave = () => {
    //     setShowError(false)
    //     if (callBackUrl === '') {
    //         setShowError(true)
    //     } else {
    //         const param = {
    //             callBackUrl: callBackUrl,
    //         };
    //         createCallBackUrl(param).then(resp => {
    //             if (resp.statusCode) {
    //                 setcallBackUrL(callBackUrl)
    //                 setMessage({ "success": resp.statusMessage })
    //             }
    //         }).catch(error => {
    //             if (error?.errorCode === "B2B_CREATE_CLIENT_CONFIGURATION_400") {
    //                 setMessage({ "error": error.errorMessage })
    //             }
    //         })
    //     }
    // }

    return (
        <>
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    {loader && <Loader />}
                    <div className='content'>
                        <div className='mt-4 mt-sm-2'>
                            <section className='' id='creditscore'>
                                <div className='container-lg container-fluid mt-3 my-sm-3'>
                                    <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                                        <div className='col-5'>
                                            <div className='ps-sm-2'>
                                                <h1 className='username-text mb-2 mb-sm-3'>Sandbox</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div >
                        <section>
                            <ErrorMessage message={message} />
                        </section>
                        <section id='apikeys'>
                            <div className='container-lg container-fluid my-3'>
                                <div className="accordion" id="accordionPanelsStayOpenExample1">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text">API Keys</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-sm-12 col-lg-8 col-md-10'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample">
                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Sandbox Client ID
                                                                </h1>

                                                                <div className='col-12 col-lg-8 col-md-8 mb-3 d-flex flex-row'>
                                                                    <div className="input-group input-box input-back pt-2 pb-2 ps-3 pe-3">
                                                                        <input
                                                                            type="text"
                                                                            id="clientid"
                                                                            name="clientid"
                                                                            readOnly
                                                                            className="form-input col-10"
                                                                            value={clientId}
                                                                        />

                                                                        <div className="col-2 d-flex justify-content-end">
                                                                            <CopyToClipboard
                                                                                onCopy={copied}
                                                                                text={clientId}>
                                                                                <span className="copy-box">
                                                                                    <img
                                                                                        src={copyIcon}
                                                                                        alt="copy"
                                                                                        className="copy-ic"
                                                                                    />
                                                                                </span>
                                                                            </CopyToClipboard>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Sandbox Client Secret
                                                                </h1>

                                                                <div className='col-12 col-lg-8 col-md-8 mb-3 d-flex flex-row'>
                                                                    <div className="input-group input-box input-back pt-2 pb-2 ps-3 pe-3">
                                                                        <input
                                                                            type="text"
                                                                            id="clientsecret"
                                                                            name="clientsecret"
                                                                            readOnly
                                                                            className="form-input col-10"
                                                                            value={clientSecretId}
                                                                        />

                                                                        <div className="col-2 d-flex justify-content-end">
                                                                            <CopyToClipboard
                                                                                onCopy={copied}
                                                                                text={clientSecretId}>
                                                                                <span className="copy-box">
                                                                                    <img
                                                                                        src={copyIcon}
                                                                                        alt="copy"
                                                                                        className="copy-ic"
                                                                                    />
                                                                                </span>
                                                                            </CopyToClipboard>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <section id='apibaseurl'>
                            <div className='container-lg container-fluid my-3'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text">API Base URL</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne1" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-sm-12 col-lg-8 col-md-10'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample1">
                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Sandbox Base URL
                                                                </h1>

                                                                <div className='col-12 col-lg-8 col-md-8 mb-3 d-flex flex-row'>
                                                                    <div className="input-group input-box input-back pt-2 pb-2 ps-3 pe-3">
                                                                        <input
                                                                            type="text"
                                                                            id="clientsecret1"
                                                                            name="clientsecret"
                                                                            readOnly
                                                                            className="form-input col-10"
                                                                            value="https://enterprise-uat.boost.money"
                                                                            onChange={(e) => { }}
                                                                        />

                                                                        <div className="col-2 d-flex justify-content-end">
                                                                            <CopyToClipboard
                                                                                onCopy={copied}
                                                                                text='https://enterprise-uat.boost.money'>
                                                                                <span className="copy-box">
                                                                                    <img
                                                                                        src={copyIcon}
                                                                                        alt="copy"
                                                                                        className="copy-ic"
                                                                                    />
                                                                                </span>
                                                                            </CopyToClipboard>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <input type="text" id='clientid' name="clientid" readOnly className='input-box form-control input-back' value="https://enterprise-uat.boost.money" onChange={(e) => { }} />
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='footer-box'>
                        <Footer />
                    </div>

                    {/* <section id='callback'>
                        <div className='container-lg container-fluid my-3'>
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                        <div className='w-100'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="acc-info-text" >Callback Configuration</p>
                                                </div>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                        <div className="accordion-body">
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <div className='account-accordion-box'>
                                                        <div className="accordion " id="accordionFlushExample">
                                                            <h1 className='text-start doc-title pb-3'>
                                                                Callback URL
                                                            </h1>
                                                            <div className='col-10 mb-3 d-flex'>
                                                                <input id='callbackurl' name="callbackurl" className='input-box form-control input-back mr-4' value={callBackUrl || ''} onChange={handleChange} />
                                                                <button className='btn-save' onClick={() => {
                                                                    configSave();
                                                                }}>Save</button>

                                                            </div>
                                                            {(showError && callBackUrl === '') && <span className="demo-tetx">Please enter valid callback url</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
            </div>

        </>
    );
}

export default Developer;
