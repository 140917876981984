import httpClientProd from "../../config/prod-api";


//getProdConfiguration for client secret & client ID using prod API
const getProdConfiguration = () =>
    new Promise((resolve, reject) => {
        httpClientProd({
            method: 'GET',
            url: 'api/v1/client/get',
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error);
            });
    });


const createProdCallBackUrl = (data) =>
    new Promise((resolve, reject) => {
        httpClientProd({
            method: 'POST',
            url: 'api/v1/client/configure',
            data: data, headers: { "Content-Type": "application/json" }
        }).then(resp => {
            resolve(resp.data);
        })
            .catch(error => {
                reject(error.response.data);
            });
    });

const getProdCallBackUrl = () =>
    new Promise((resolve, reject) => {
        httpClientProd({
            method: 'GET',
            url: 'api/v1/client/configuration',
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });


const getUserProdList = (page, sizePerPage) =>
    new Promise((resolve, reject) => {
        httpClientProd({
            method: 'GET',
            url: `api/v1/get/test-users?page=${page ?? 0}&sizePerPage=${sizePerPage ?? 5}`
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error);
            });
    });


export {
    getProdConfiguration,
    createProdCallBackUrl,
    getProdCallBackUrl,
    getUserProdList,
    
}