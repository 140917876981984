import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { setAuthHeader } from '../../../config/api';
import { dispatchAction } from '../../../redux/actions/common_action';
import { registerUser } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import './createanaccount.css';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';

const Logo = require("../../../images/assets/Logo.png");

function CreateAccount() {
    const [bussinessname, setBussinessName] = useState('');
    const [fullname, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [passwrd, setPasswrd] = useState('');
    const { state } = useLocation();
    const [isAcceptTerm, setIsAccepTerm] = useState(false)
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [fillupdetails, setFillupdetails] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (user?.access_token) {
            navigate('/dashboard');
        }
    }, [])

    const loginPage = () => {
        navigate('/login');
    }

    const save = () => {
        setUserExists('');
        setFillupdetails('');
        setShowError(false);
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (bussinessname === '' || fullname === '' || email === '' || passwrd === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setUserExists('Please enter valid email address');
        } else {
            const param = {
                "businessName": bussinessname,
                "fullName": fullname,
                "email": email,
                "password": passwrd
            };
            setLoader(true)
            registerUser(param).then(resp => {
                setLoader(false);
                if (resp.statusCode === "SUCCESS") {
                    navigate('/email-verification', { state: { email: email } });
                }
            }).catch(error => {
                if (error && error?.response && error?.response.data) {
                    setUserExists(error?.response.data.errorMessage)
                }else{
                    setUserExists("Something went wrong!")
                }
                setLoader(false);
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            save()
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-2'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                {/* <h1 >
                                    Start your <span className='boost-text'>boost</span> journey
                                </h1> */}
                                <h1 className='color'>
                                    Start your Business Journey with Boost.Money!
                                </h1>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-8 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Create An Account
                                    </h1>
                                    <div className='form-input-box mt-md-4'>
                                        <form>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Business Name</div>
                                                </div>
                                                <div>
                                                    <input type="text" name='bussinessname' id='bussinessname' className='input-box form-control' value={bussinessname} onChange={(e) => { setBussinessName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && bussinessname === '') && <span className="demo-tetx py-0 my-0">Please enter your bussiness name</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Full Name</div>
                                                </div>
                                                <div>
                                                    <input type="text" name='fullname' id='fullname' className='input-box form-control' value={fullname} onChange={(e) => { setFullName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && fullname === '') && <span className="demo-tetx py-0 my-0">Please enter your full name</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Email</div>
                                                </div>
                                                <div>
                                                    <input type="text" id='email' className='input-box form-control' value={email} onChange={(e) => { setEmail(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && email === '') && <span className="demo-tetx">Please enter your email</span>}
                                                    {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                                </div>
                                            </div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Password</div>
                                                </div>
                                                <div>
                                                    <input type="password" id='passwrd' className='input-box form-control' value={passwrd} onChange={(e) => { setPasswrd(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && passwrd === '') && <span className="demo-tetx">Please enter your password</span>}
                                                </div>
                                            </div>

                                            <div className='form-inner-box'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-sm-12 col-md-6 order-2 order-sm-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span className='any-account-text'>Have an account? </span><span className='login-text' onClick={() => {
                                                                loginPage()
                                                            }}>Login</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6 order-1 order-sm-2'>
                                                        <div className='text-center text-sm-center text-md-end'>
                                                            <button type='button' className='next-button' onClick={() => {
                                                                save()
                                                            }} >
                                                                Register
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default CreateAccount;