import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { setAuthHeader } from '../../../config/api';
import { loginAction, otpVerification } from '../../../redux/actions/auth_action';
import { dispatchAction } from '../../../redux/actions/common_action';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
import Loader from '../../loader/loader';
import './otp.css';
import Footer from '../../landing-page/Footer/Footer';
import setAuthHeaderProd from '../../../config/prod-api';
const Logo = require("../../../images/assets/Logo.png");

function OTP() {
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [userExists, setUserExists] = useState();
    const [resendOtp, setResendOtp] = useState()
    const navigate = useNavigate();
    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (user?.access_token) {
            navigate('/dashboard');
        } else if (state === null) { }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const verifyOtp = () => {
        setUserExists('')
        setShowError(false)
        if (!otp || otp === '') {
            setShowError(true)
        } else {
            const param = {
                "mobileNumber": state.mobileNumber,
                "otp": otp
            }
            setLoader(true)
            otpVerification(param).then(resp => {
                if (resp.statusCode === "OTP_VERIFIED") {
                    setLoader(false)
                    AsyncStorage.setItem('token', resp.data.access_token)
                    setAuthHeader(resp.data.access_token)
                    setAuthHeaderProd(resp.data.access_token)
                    dispatch(dispatchAction(SIGN_IN_SUCCESS, resp.data, false));
                    if (state.from === 'login') {
                        navigate('/dashboard')
                    } else {
                        navigate('/emailverification')
                    }
                } else {

                    dispatch(dispatchAction(SIGN_IN_ERROR, resp.data.error, true));
                }
            }).catch(error => {
                setLoader(false)
                dispatch(dispatchAction(SIGN_IN_ERROR, error.message, true));
                setUserExists(error?.response.data.errorMessage)
                if (error?.response.data.errorCode === "SIGN_IN_ERROR") {
                }
            });
        }
    }

    const login = () => {
        setResendOtp('')
        if (state.mobileNumber === undefined || state.mobileNumber === '') {
        } else {
            const param = {
                "mobileNumber": state.mobileNumber
            };
            setLoader(true)
            loginAction(param).then(resp => {
                setLoader(false)
                setSeconds(30)
                setResendOtp("Resend OTP")
            }).catch(error => {
                setLoader(false)
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            verifyOtp()
        }
    };

    return (

        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score..
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0  form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className='text-start '>
                                        OTP Verification
                                    </h1>
                                    <p className='mt-md-3'>
                                        Enter the OTP you would have received on your registered number
                                    </p>
                                    <div className='form-input-box otp-box'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className='mb-1 mb-md-2'>
                                                    <div className='label-label'>4 Digit otp </div>
                                                </div>
                                                <div>
                                                    <input type="tel" className='input-box form-control' minLength="4" maxLength="4" autoComplete='off' value={otp}
                                                        pattern="\d{3}-\d{3}-\d{4}"
                                                        onChange={(e) => {
                                                            setOtp(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    {(showError && (!otp || otp === '')) && <span className="demo-tetx">Please enter your OTP</span>}
                                                    {userExists !== '' && <span className='demo-tetx mt-2'>{userExists}</span>}
                                                    {resendOtp !== '' && <p className='demo-tetx mt-1'>{resendOtp}</p>}
                                                </div>
                                            </div>
                                            <div className='resend-otp-box'>
                                                {seconds !== 0 && <div className='form-inner-box mb-1 my-mb-5'>
                                                    <div className='text-end text-sm-end my-1 my-md-5'>
                                                        <div className='getotp-text'>
                                                            Resend OTP in  {seconds}
                                                        </div>
                                                    </div>
                                                </div>}
                                                {seconds === 0 && <div className='form-inner-box mb-1 my-mb-5'>
                                                    <div className='text-end text-sm-end my-1 my-md-5'>
                                                        <div className='getotp-text' onClick={() => {
                                                            login();
                                                        }}>
                                                            Resend OTP
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className='form-inner-box align-items-end my-1 my-md-5 otp-button-box'>
                                                <div className='text-center text-sm-end my-1 my-md-5'>
                                                    <button type='button' className='next-button' onClick={() => {
                                                        verifyOtp();
                                                    }}>Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default OTP;