import { Fragment } from "react";

const PdfIC = require("../../../images/assets/pdf-ic.png");
const ImgIC = require("../../../images/assets/img-ic.png");


function ListDocComponent(props) {

  let salaryDocList = {
    "statusCode": "SUCCESS",
    "statusMessage": "Documents fetched successfully.",
    "data": [
      {
        "documentType": "Income Documents",
        "documents": [
          {
            "title": "Salary",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf",
              "Account_stmt_XX2313_05022024[2].pdf",
              "Account_stmt_XX2313_05022024[3].pdf",
              "Account_stmt_XX2313_05022024[4].pdf"
            ]
          },
          {
            "title": "Form 16",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf",
              "Account_stmt_XX2313_05022024[2].pdf"
            ]
          },
          {
            "title": "TX 16",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf",
              "Account_stmt_XX2313_05022024[2].pdf"
            ]
          }
        ]
      },
      {
        "documentType": "KYC Documents",
        "documents": [
          {
            "title": "Aadhar Card",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf",
              "Account_stmt_XX2313_05022024[2].pdf"
            ]
          },
          {
            "title": "Pan Card",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf"
            ]
          }
        ]
      },
      {
        "documentType": "Address Proof",
        "documents": [
          {
            "title": "Electricity Bill",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf",
              "Account_stmt_XX2313_05022024[2].pdf"
            ]
          },
          {
            "title": "Rental Agreement",
            "files": [
              "Account_stmt_XX2313_05022024[1].pdf"
            ]
          }
        ]
      }
    ]
  }



  return (
    <>
      <div className='docs-parent-top py-3 m-0'>
        <section id="docs-section">
          <div className="container-lg container-fluid">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              {salaryDocList.data.map((docType, docTypeIndex) => (
                <div key={Math.random() + docTypeIndex} className={"mb-3 "}>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header accordion-button align-items-start pb-0 cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapse${docTypeIndex}`}
                      aria-expanded="true"
                      aria-controls={`panelsStayOpen-collapse${docTypeIndex}`}
                    >
                      <div className="w-100 p-0 m-0">
                        <div className="d-flex justify-content-between p-0 m-0">
                          <p className="acc-info-text p-0 m-0 pb-2 doc-main-title text-capitalize">
                            {docType.documentType} ({docType.documents.length})
                          </p>
                        </div>
                      </div>
                    </h2>
                    <div
                      id={`panelsStayOpen-collapse${docTypeIndex}`}
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 m-0">
                        <div className="row m-0 p-0">
                          <div className="col-12 m-0 p-0">
                            <div className="account-accordion-box">
                              <div className="accordion ps-1 pe-2"
                                key={Math.random()}
                                id={`accordionFlushExample${docTypeIndex}`}>
                                <div className="pt-3 pb-3">
                                  <div className="col-12 flex-column">
                                    <div className="row p-0 m-0">
                                      {docType.documents.map((doc, docIndex) => (
                                        <div
                                          key={Math.random() + docIndex}
                                          className="col-12 pt-1">
                                          <p className="p-0 m-0 px-2 doc-sub-title">{doc.title}</p>
                                          <div className="ms-2">
                                            <div className="ps-0 my-2 info-details-box">
                                              <div className="p-0 m-0 pe-0 py-2">
                                                <div className="row p-0 m-0">
                                                  {doc.files.map((file, fileIndex) => (
                                                    <div
                                                      key={Math.random() + fileIndex}
                                                      className="col-12 col-sm-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-start ps-3 py-2 c-contain-content"
                                                    >
                                                      <div className="d-flex flex-row p-0 m-0 justify-content-center align-content-center">
                                                        <img src={PdfIC} alt="pdf" className="doc-pdf-ic" />
                                                        <p className="p-0 m-0 ps-1 doc-item-name">{file}</p>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className='col-12 text-center'>
          <button className='mb-0 download-zip-btn' onClick={() => {
            props.downloadDocuments();
          }}>Download ZIP Folder</button>
        </div>
      </div>

    </>
  );
}
export default ListDocComponent;
