import MenuBar from "../../Menubar/menubar";
import Sidebar from "../../SideBar/sidebar";
import Footer from "../../../landing-page/Footer/Footer";
import ProfileHeaderBanner from "../profile_component/profile_header";
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./change_password.css";
import { changePassword } from "../../../../redux/actions/dashboard_action";
import { useEffect, useState } from "react";
import ErrorMessage from "../../error-message/error-message";

const Avatar_logo = require("../../../../images/avatar-profile.png");
const IC_CAMERA = require("../../../../images/assets/ic-camera.png");
const IC_EYE = require("../../../../images/assets/show-password.png");
const IC_EYE_HIDE = require("../../../../images/assets/hide-password.png");
const ChangePassword = () => {

  const [currPassword, setCurrPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loader, setLoader] = useState(false)
  const [showError, setShowError] = useState(false)
  const [checkPass, setCheckPass] = useState(false)
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isRevealCurrentPwd, setIsRevealCurrentPwd] = useState(false);
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const navigate = useNavigate();

  let fullName = localStorage.getItem("fullName")
  let businessName = localStorage.getItem("businessName")

  const clickChangePassword = () => {
    setErrorMessage('')
    setMessage('')
    setShowError(false)
    setCheckPass(false)
    if (currPassword === '' || newPassword === '' || confirmPassword === '') {
      setShowError(true)
    } else if (newPassword != confirmPassword) {
      setCheckPass(true)
    } else {
      const params = {
        currentPassword: currPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmPassword
      }
      changePassword(params).then(resp => {
        setLoader(false);
        if (resp.statusCode === "SUCCESS") {
          setCurrPassword('')
          setNewPassword('')
          setConfirmPassword('')
          setMessage({ "success": resp.statusMessage })
          //setMessage(resp.statusMessage)
        }
      }).catch(error => {
        setLoader(false);
        if (error && error?.response && error?.response.data) {
          setErrorMessage(error?.response.data.errorMessage)
        } else {
          setErrorMessage("Something went wrong!")
        }

      })
    }
  }

  const navigateProfile = () => {
    navigate('/profile')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      changePassword()
    }
  };



  return (
    <>
      <MenuBar />
      <div className="sidebar-box sidnav-box">
        <Sidebar></Sidebar>
        <div id="main_section" className="sidebat-inner-box sidnav-inner-box">
          <ProfileHeaderBanner name={fullName} businessName={businessName}></ProfileHeaderBanner>
          <section className="profile-sub-section">
            <p className="profile-sub-section-label">Change Password</p>
            <div className="profile-sub-section-divider"></div>
          </section>
          <section className="profile-text-form col-12 col-sm-12 col-md-6 col-lg-5">
            <ErrorMessage message={message}></ErrorMessage>

            <p className="profile-text-field-label">Current Passowrd</p>
            <div className="password-suffix-box profile-passowrd-field-layout ps-2">
              <input
                type={isRevealCurrentPwd ? "text" : "password"}
                className="password-input col-11 col-lg-11 col-md-10 col-sm-11"
                placeholder="Current passowrd"
                value={currPassword}
                onChange={(e) => { setCurrPassword(e.target.value) }} onKeyDown={handleKeyDown}
              ></input>
              <img
                title={isRevealCurrentPwd ? "Hide password" : "Show password"}
                src={isRevealCurrentPwd ? IC_EYE_HIDE : IC_EYE}
                onClick={() => setIsRevealCurrentPwd(prevState => !prevState)}
                className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1" />
            </div>
            {(showError && currPassword === '') && <span className="demo-tetx">Please enter your current password</span>}

            <p className="profile-text-field-label">New Passowrd</p>
            <div className="password-suffix-box profile-passowrd-field-layout ps-2 ">
              <input
                type={isRevealNewPwd ? "text" : "password"}
                className="password-input col-11 col-lg-11 col-md-10 col-sm-11"
                placeholder="New passowrd"
                value={newPassword}
                onChange={(e) => { setNewPassword(e.target.value) }} onKeyDown={handleKeyDown}
              ></input>
              <img title={isRevealNewPwd ? "Hide password" : "Show password"}
                src={isRevealNewPwd ? IC_EYE_HIDE : IC_EYE}
                onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1" />
            </div>
            {(showError && newPassword === '') && <span className="demo-tetx">Please enter your new password</span>}

            <p className="profile-text-field-label">Confirm New Passowrd</p>
            <div className="password-suffix-box profile-passowrd-field-layout ps-2">
              <input
                type={isRevealConfirmPwd ? "text" : "password"}
                className="password-input col-11 col-lg-11 col-md-10 col-sm-11"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value) }} onKeyDown={handleKeyDown}
              ></input>
              <img title={isRevealConfirmPwd ? "Hide password" : "Show password"}
                src={isRevealConfirmPwd ? IC_EYE_HIDE : IC_EYE}
                onClick={() => setIsRevealConfirmPwd(prevState => !prevState)} className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1" />
            </div>
            {(showError && confirmPassword === '') && <span className="demo-tetx">Please enter your confirm password</span>}
            {(checkPass) && <span className="demo-tetx">Please verify, New password doesn't match with the confirm password</span>}
            {(errorMessage) && <span className="demo-tetx">{errorMessage}</span>}


            <div className="pt-4 d-flex flex-wrap justify-content-between">
              <button className="btn-cancel m-0 mt-2 col-5" onClick={() => { navigateProfile() }}>
                Cancel
              </button>
              <button className="btn-gradient m-0 mt-2 col-6" onClick={() => { clickChangePassword() }}>
                Save Changes
              </button>
            </div>
          </section>
          <section className="space"></section>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default ChangePassword;
